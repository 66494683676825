import 'keen-slider/keen-slider.min.css'

import KeenSliderHelper from '~/classes/keen-slider-helper'
import SlidePoints from '~/classes/slide-points'
import { googleDataMarketingBannerClick  } from '~/helpers/google-datalayer'

window.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('banners-top') as HTMLElement

    initClickListener(container)

    if (!container) return void 0

    const slider = new KeenSliderHelper(
        container,
        {
            loop: true,
        }
    )

    container.addEventListener('slidePointOpen', () => {
        slider.autoplayStop()
    })

    container.addEventListener('slidePointClose', () => {
        slider.autoplayStart()
    })

    if (slider.slidesCount > 1) {
        slider.autoplay(10000)
    }

    slider.createAdditionalMarkup({
        arrows: {
            enable: true,
        },
        dots: {
            enable: true
        },
        counter: {
            enable: false
        },
        popup: {
            enable: true,
            selector: '.slider-banners__adv-button',
            position: 'left',
        }
    })

    useVHHAck()

    container.classList.remove('opacity-0')
})

const useVHHAck = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const initClickListener = (target: HTMLElement) => {
    target.addEventListener('click', handleClick)
}

const handleClick = (e: Event) => {
    const eventTarget = e.target as HTMLElement
    const currentSlide = eventTarget.closest<HTMLElement>('.slider-banners__slide-link')

    if (currentSlide) {
        googleDataMarketingBannerClick(currentSlide)
    }
}